.KnowledgeBaseModifyFolderModal {
    &-loader {
        position: relative;
        width: 100%;
        height: 100px;

        .loader {
            color: #0e3560 !important;

            &::before {
                border-color: rgb(0 0 0 / 15%) !important;
            }
            &::after {
                border-color: #000 transparent transparent !important;
            }
        }
    }

    .item[role='listitem'] {
        padding: 10px !important;
        background: transparent;
        transition: all 0.15s;
        cursor: pointer;

        div.item-avatar {
            width: 32px;
            height: 32px;
            float: left;
            border-radius: 100px;
            background: #ebebeb;
            margin-right: 10px;

            svg {
                margin: 7px;
            }
        }

        .content {
            line-height: 31px !important;
            font-size: 16px;

            svg {
                float: right;
                margin: 4px 0;
            }
        }

        &:hover,
        &:focus {
            background: #f2f4f5;
        }
    }

    center {
        svg:not(a svg) {
            color: #cecece;
            margin-bottom: 20px;
        }
        span {
            font-size: 18px;
            color: rgba(0, 0, 0, 0.87);
            font-style: italic;
            letter-spacing: 0.5px;
        }
        h1 {
            margin-top: 0;
        }

        a {
            display: inline-block;
            line-height: 24px;
            padding: 0 10px;
            margin-top: 10px;
            cursor: pointer;
            font-size: 13px;
            color: #db2828;

            svg {
                margin: 4px 5px 4px 0;
                float: left;
            }

            &:hover,
            &:focus {
                color: darken(#db2828, 10%);
            }

            &.permissions-link {
                margin-top: 20px;
                color: #2185d0;

                &:hover,
                &:focus {
                    color: darken(#2185d0, 10%);
                }
            }
        }
    }
}
