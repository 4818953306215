.settings_content {
    center {
        padding: 20px 0;
        svg {
            color: #cecece;
            margin-bottom: 20px;
        }
        span {
            font-size: 18px;
            color: rgba(0, 0, 0, 0.87);
            font-style: italic;
            letter-spacing: 0.5px;
        }
        h1 {
            margin-top: 0;
        }
    }
}
