@import '../../scss/variables';

body {
    overflow: hidden;
}

.create-group-modal-hint {
    font-size: 13px;
    margin-top: 5px;
    display: inline-block;
    color: #5f768f;
}
.create-group-modal-banner {
    width: calc(100% + 3rem);
    height: 150px;
    object-fit: cover;
    margin: -1.5rem -1.5rem 50px -1.5rem;
}
.create-group-modal-avatar {
    width: 75px;
    height: 75px;
    top: calc(150px - (75px / 2));
    left: 20px;
    position: absolute;
    box-shadow: 0 0 0 4px white;
    border-radius: 1000px;
    background: white;
}
.groups-content {
    width: 100%;
    display: inline-block;

    center {
        padding-top: 50px;
        svg {
            color: #cecece;
            margin-bottom: 20px;
        }
        span {
            font-size: 18px;
            color: rgba(0, 0, 0, 0.87);
            font-style: italic;
            letter-spacing: 0.5px;
        }
        h1 {
            margin-top: 0;
        }
    }
}
.group-topics {
    width: 290px;
    float: left;
    border-radius: 10px !important;
    box-shadow: 0 1px 3px 0 #d4d4d5;
    background: white !important;
    padding: 15px !important;

    &-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 11px 10px;
        background: white;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        color: #0e3560;
        margin-bottom: 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        transition: all 0.2s !important;
        &:hover {
            background-color: #eeeeee;
            transform: scale(0.98);
        }
        svg {
            margin-left: 5px;
            margin-right: 15px;
            opacity: 0.7;
        }
        &:active {
            transform: scale(0.95);
            outline: none !important;
        }
        &:last-of-type {
            margin-bottom: 0 !important;
        }
    }

    @media (max-width: 1000px) {
        width: 100%;
    }
}
.group-list {
    width: calc(100% - 290px - 20px);
    float: left;
    margin-left: 20px;

    @media (max-width: 1000px) {
        width: 100%;
        margin: 20px 0 0 0;
    }

    &-item {
        position: relative;
        width: 100%;
        border-radius: 10px !important;
        box-shadow: 0 1px 3px 0 #d4d4d5;
        background: white !important;
        margin-bottom: 20px;

        &-banner {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
        }
        &-avatar {
            position: absolute;
            width: 75px;
            height: 75px;
            top: calc(200px - (75px) / 2);
            left: 20px;
            border-radius: 100%;
            background: white;
            box-shadow: 0 0 0 4px white;
        }

        &-content {
            width: 100%;
            padding: 50px 20px 20px 20px;
        }
        &-title {
            font-size: 19px;
            font-weight: 600;
        }
        &-description {
            font-size: 15px;
            width: 100%;
            margin: 10px 0;
        }
        &-members {
            font-size: 13px;
            font-weight: 400;
            color: #687787;
            margin-left: 5px;
        }
    }
}
