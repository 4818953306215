@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/jetbrainsmono/v3/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yKxTOlOTk6OThhvA.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
    overflow: hidden;
}

.loginContainer {
    margin: 0;
    width: 500px;
    height: 100%;
    background: white;
    float: left;
    text-align: left;
    padding: 50px 35px;
    img.logo {
        height: 40px;
    }
    h3 {
        margin-bottom: 23px;
        font-size: 21px;
        font-weight: bold;
        color: #2c86d0;
    }
    .formContainer {
        border-top: 1px solid #dededf;
        padding-top: 25px;
        margin-top: 25px;
        .ui.button {
            margin: 5px;
        }
        .or-text {
            display: block;
            font-size: 16px;
            margin: 15px 0;
        }
    }
    #userPIN {
        text-align: center;
        letter-spacing: 1em;
        font-size: 17px;
        padding-right: 0 !important;
        font-family: 'JetBrains Mono', monospace !important;
    }
}

.login-card {
    padding: 15px !important;
    width: 100% !important;
    margin-top: 50px !important;
    background: #fdfdfd !important;
}
