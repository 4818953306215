html,
body {
    width: 100%;
    height: 100%;
    overflow: hidden !important;
}

.sidebar_left {
    float: left;
    width: 290px;
}

@media (max-width: 1130px) {
    .sidebar_left {
        display: none;
    }
}
