@import '../../../scss/variables';
html,
body {
    width: 100%;
    height: 100%;
    overflow: hidden !important;
}

.skip-to-content {
    font-size: 18px;
    background: #2c85d0;
    color: white;
    padding: 10px 19px;
    display: inline-block;
    cursor: pointer;
    border: none;
    text-align: center;
    text-decoration: underline;
    font-weight: 500;
    position: absolute;
    border-radius: 7px;
    top: 5px;
    left: calc((100% - 1300px) / 2);
    z-index: 10;
    line-height: 20px;
    box-shadow: 0 1px 3px 0 #d4d4d5;
    opacity: 1;
    outline: 0;

    &:not(:focus) {
        top: -1000px;
        left: -1000px;
        opacity: 0;
    }
    &:focus,
    &:hover,
    &:active {
        color: white;
        text-decoration: underline;
    }
}

.nav-header {
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0 calc((100% - 1300px) / 2);
    border-radius: 0 0 10px 10px;
    background-color: white;
    margin: 0 0 20px 0;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    z-index: 9;
    .header__logo {
        height: 30px;
        margin: 10px 0;
        float: left;
        img {
            height: 100%;
        }
        transition: all 0.2s !important;
        &:hover {
            transform: scale(0.98);
        }
        &:active {
            transform: scale(0.95);
            outline: none !important;
        }
        &:last-of-type {
            margin-bottom: 0 !important;
        }
    }
    .header__menu-items {
        float: right;
        height: 100%;
        .header__menu-item {
            display: inline-block;
            line-height: 40px;
            font-size: 17px;
            color: #0e3560;
            font-weight: 600;
            background: white;
            padding: 0 12px;
            border-radius: 7px;
            margin: 5px 0 5px 5px;
            transition: all 0.2s;
            &:hover {
                background-color: #eeeeee;
                transform: scale(0.98);
            }
            &--active {
                background-color: #f7f7f7;
                color: $primary-color;
            }
            &:active {
                transform: scale(0.93);
                outline: none !important;
            }
        }
        .header__menu-mobile-close {
            display: none;
            float: left;
            height: 40px;
            width: 40px;
            border-radius: 100px;
            background: #f7f7f7;
            margin: 5px 15px 5px 5px;
            text-align: center;
            cursor: pointer;
            &:hover {
                color: $primary-color;
            }
            svg {
                margin: 8px;
            }
        }
        &--mobile-visible {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
            display: block !important;
            background: white;
            width: 100vw;
            height: unset;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            .header__menu-mobile-close {
                display: inline-block;
            }
        }
    }
    .header__dropdown-button,
    .header__menu-mobile-button {
        float: right;
        height: 40px;
        width: 40px;
        border-radius: 100px;
        background: #f7f7f7;
        margin: 5px 0 5px 15px;
        text-align: center;
        cursor: pointer;
        transition: all 0.2s;
        position: relative;
        &:hover {
            color: $primary-color;
            background-color: #eeeeee;
            transform: scale(0.98);
        }
        svg {
            margin: 8px;
        }
        &:active {
            transform: scale(0.93);
            outline: none !important;
        }
    }
    .header__dropdown-button-notification-indicator {
        position: absolute;
        bottom: -4px;
        right: -4px;
        width: 17px;
        height: 17px;
        background: #2c85d0;
        border-radius: 100px;
        border: 3px solid white;
    }
    .header__menu-mobile-button {
        display: none;
    }
}

@media (max-width: 1300px) {
    .nav-header {
        margin-top: 0;
        padding: 0 10px;
    }
}

@media (max-width: 650px) {
    .nav-header {
        .header__menu-items {
            display: none;
        }
        .header__menu-mobile-button {
            display: inline-block;
        }
    }
}
