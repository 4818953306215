.hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    color: #0e3560;
    background: #f7f7f7;
}
.hljs-comment,
.hljs-quote {
    color: #998;
    font-style: italic;
}
.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
    color: #0e3560;
}
.hljs-literal,
.hljs-number,
.hljs-tag .hljs-attr,
.hljs-template-variable,
.hljs-variable {
    color: teal;
}
.hljs-doctag,
.hljs-string {
    color: #d14;
}
.hljs-section,
.hljs-selector-id,
.hljs-title {
    color: #900;
}
.hljs-subst {
    font-weight: 400;
}
.hljs-class .hljs-title,
.hljs-type {
    color: #458;
}
.hljs-attribute,
.hljs-name,
.hljs-tag {
    color: navy;
    font-weight: 400;
}
.hljs-link,
.hljs-regexp {
    color: #009926;
}
.hljs-bullet,
.hljs-symbol {
    color: #990073;
}
.hljs-built_in,
.hljs-builtin-name {
    color: #0086b3;
}
.hljs-meta {
    color: #999;
}
.hljs-deletion {
    background: #fdd;
}
.hljs-addition {
    background: #dfd;
}
.hljs-emphasis {
    font-style: italic;
}
