html,
body {
    width: 100%;
    height: 100%;
    overflow: hidden !important;
}

.content_container {
    float: left;
    width: calc(100% - 290px - 290px);
    padding: 0 30px 30px 30px;
    .text.extra * {
        word-break: break-word;
    }
}

@media (max-width: 1130px) {
    .content_container {
        width: 100%;
        padding: 0 5px 5px 5px;
    }
}
