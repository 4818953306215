.new_post {
    .card {
        width: 100% !important;
        margin: 0 !important;
        border-radius: 8px !important;

        .input {
            width: 100% !important;
            cursor: pointer !important;
            input {
                cursor: pointer !important;
                border-radius: 1000px !important;
            }
        }
    }
}
.modals {
    justify-content: normal !important;
    .modal {
        flex: none !important;
        margin-top: 20px !important;
        border-radius: 10px !important;

        .header {
            border-radius: 10px 10px 0 0 !important;
        }
        .actions {
            border-radius: 0 0 10px 10px !important;

            .left-actions {
                float: left !important;
            }
        }

        .content:not(.item > .content) {
            max-height: 600px;
            overflow: auto;
        }

        .fake-textarea {
            width: 100%;
            min-height: 100px;
            border: none;
            outline: none;
            font-size: 17px;
        }
        .fake-textarea[placeholder]:empty:before {
            content: attr(placeholder);
            color: #555;
            font-size: 17px;
        }

        .uploaded-images,
        .uploaded-files {
            width: 100%;
            margin-top: 20px;

            &-item {
                width: 100%;
                height: 45px;
                background: #f5f5f5;
                margin-top: 10px;
                border-radius: 10px;
                border: 1px solid #d9dadc;

                &-icon {
                    height: 35px;
                    width: 35px;
                    float: left;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: white;
                    margin: 4px;
                    border-radius: 10px;
                    border: 1px solid #d9dadc;
                }
                &-title {
                    line-height: 43px;
                    font-size: 16px;
                    font-weight: 500;
                    padding: 0 5px;
                    display: inline-block;
                    max-width: calc(100% - 86px);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                &-remove {
                    float: right;
                    height: 35px;
                    width: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #7e8d98;
                    margin: 4px;
                    border-radius: 10px;
                    transition: all 0.2s;

                    &:hover {
                        color: #0e3560;
                        background: white;
                        transform: scale(0.92);
                    }
                    &:active {
                        transform: scale(0.85);
                    }
                    &:focus {
                        color: #0e3560;
                        background: white;
                    }
                }
            }
        }
    }
}
.dimmer {
    background-color: #00000080 !important;
}
