@import '../../../scss/variables';
.event {
    border-radius: 0.28571429rem 0.28571429rem 0 0 !important;
    box-shadow: 0 1px 3px 0 #d4d4d5;
    background: white !important;
    margin-top: 20px !important;
    padding: 15px !important;
    width: 100% !important;
    &--pinned {
        border: 2px solid #2185d0 !important;
        box-shadow: 0 1px 3px 0 #2185d0 !important;
    }
    .content {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        .meta {
            margin-top: 10px !important;
            width: 100%;
        }
        .text.collapsed {
            div {
                max-height: 90px;
                overflow: hidden;
                position: relative;
                &::after {
                    content: ' ';
                    width: 100%;
                    height: 0;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    box-shadow: 0px 0 20px 31px white;
                }
            }
        }
    }
    .dropdown {
        line-height: revert !important;
        float: right;
        .dropdown.icon {
            display: none;
        }
        .menu {
            border-radius: 10px !important;
            padding: 10px !important;
            .item {
                border-radius: 10px;
                transition: all 0.1s;
            }
        }
    }
    a.toggle-long-text-button {
        margin-top: 7px;
        display: inline-block;
        font-size: 13px;
        color: #818e9b;
        text-decoration: underline;
        opacity: 0.7;
        &:hover,
        &:focus {
            opacity: 1;
        }
    }
    .post-images,
    .post-files {
        width: 100%;
        display: inline-block;
        padding-bottom: 10px;
        margin-top: 10px;
    }
    .post-image {
        width: 50%;
        height: 150px;
        float: left;
        padding: 5px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
        }
        &--single {
            padding: 5px 0 !important;
            width: 100% !important;
            height: 250px !important;
        }
        @media (max-width: 800px) {
            width: 100%;
            height: 200px;
        }
    }
    .post-user-avatar {
        height: 40px !important;
        margin: 5px 0 !important;
    }
    .post-file {
        width: 100%;
        height: 45px;
        background: #f5f5f5;
        margin-top: 10px;
        border-radius: 10px;
        border: 1px solid #d9dadc;
        display: inline-block;
        &-icon {
            height: 35px;
            width: 35px;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            background: white;
            margin: 4px;
            border-radius: 10px;
            border: 1px solid #d9dadc;
            color: #0e3560;
        }
        &-text {
            line-height: 43px;
            font-size: 16px;
            font-weight: 500;
            padding: 0 5px;
            display: inline-block;
            max-width: calc(100% - 86px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #0e3560;
        }
    }
    &--no-comments-visible {
        border-radius: 8px !important;
    }
    &:not(&--no-comments-visible) {
        border-radius: 8px 8px 0 0 !important;
    }
    .empty-feed {
        text-align: center;
        width: 100%;
        padding: 20px 0;
        svg {
            color: #cecece;
            margin-bottom: 20px;
        }
        span {
            font-size: 18px;
            color: rgba(0, 0, 0, 0.87);
            font-style: italic;
            letter-spacing: 0.5px;
        }
    }
    a.report-button,
    a.share-button,
    a.administrate-button {
        position: relative;
        float: right;
        svg {
            margin-right: 0;
            margin-left: 5px;
        }
        span {
            touch-action: none;
            pointer-events: none;
            opacity: 0;
            position: absolute;
            left: 50%;
            top: -15px;
            transform: translateX(-50%);
            background: #e0e6eb;
            z-index: 10;
            touch-action: none;
            padding: 0 5px;
            border-radius: 6px;
            transition: all 0.2s;
        }
        &:hover,
        &:focus {
            span {
                padding: 0 10px;
                top: -25px;
                opacity: 1;
            }
        }
    }
    .user-avatar {
        outline: 0;
        a {
            display: inline-block;
            width: 35px;
            height: 35px;
        }
        img {
            height: 100% !important;
            object-fit: cover;
        }
    }
    .user {
        &--group-member {
            color: #a3aebb;
        }
    }
}

.feed,
.comment {
    a.liked,
    a.true {
        svg {
            fill: #c5d0d6;
        }
        span {
            color: $primary-color !important;
        }
    }
    a.like,
    a.comment-button,
    a.report-button,
    a.share-button,
    a.administrate-button {
        padding: 2px 2px;
        display: flex !important;
        align-items: center;
        font-weight: 500;
        width: fit-content;
        svg {
            margin-right: 5px;
            pointer-events: none;
            * {
                pointer-events: none;
            }
        }
    }
}

.feed .comments {
    width: 100% !important;
    max-width: 100% !important;
}

.ui.feed > .event > .label {
    height: 2.5em !important;
}

.comments-group {
    border-radius: 0 0 8px 8px;
    box-shadow: 0 2px 3px 0 #d4d4d5;
    background: white !important;
    padding: 15px !important;
    margin-top: -1px !important;
    border-top: 1px solid #eaeaea;
}

.load-more-container {
    width: 100%;
    padding: 20px 0;
    text-align: center;
}

.ui.text.loader {
    top: auto !important;
    transform: translateX(-50%) translateY(30px) !important;
}

.image-modal-content {
    box-shadow: none !important;
    background: transparent !important;
    .content {
        padding: 0 !important;
        overflow: hidden !important;
        background: transparent !important;
        border-radius: 10px !important;
        height: 600px !important;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.ui.feed > .event > .content .extra.text {
    max-width: 100% !important;
}
