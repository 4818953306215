@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import 'variables.scss';
* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif !important;
}
body {
    color: #0e3560 !important;
}

#root,
.app {
    width: 100%;
    height: 100%;
    overflow: hidden !important;
}

.ui.button {
    border-radius: 10px !important;
    transition: all 0.2s !important;
    &:hover {
        background-color: #eeeeee;
        transform: scale(0.98);
    }
    &:active {
        transform: scale(0.95);
        outline: none !important;
    }
}

.ui.message {
    border-radius: 10px !important;
}

.input input,
input.search,
textarea,
.ui.dropdown {
    box-shadow: 0 0 0px 0px rgb(33 133 208 / 40%) !important;
    border-radius: 10px !important;
    transition: all 0.2s, border-radius 0.1s !important;
}
.ui.dropdown {
    line-height: 1.21428571em;
}
.ui.selection.dropdown .menu {
    border-radius: 0 0 10px 10px !important;
}

.input input:focus,
input.search:focus,
textarea:focus,
.ui.dropdown:focus {
    box-shadow: 0 0 0px 3px rgb(33 133 208 / 40%) !important;
}

.message {
    .header {
        box-shadow: none;
        background: none;
        margin: 0;
        line-height: unset;
        height: auto;
    }
}

.ui.pagination {
    margin-bottom: 25px !important;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
    font-size: 16px !important;
}

.ui.checkbox:not(.toggle) .box:before,
.ui.checkbox:not(.toggle) label:before {
    border-radius: 5px !important;
}
.ui.checkbox label:active,
.ui.checkbox label:focus,
.ui.checkbox input:focus ~ label {
    &:before {
        box-shadow: 0 0 0px 3px rgb(33 133 208 / 40%) !important;
    }
}
.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
    background: #f9fafb !important;
    border-color: rgb(44 133 208) !important;
}
.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
    font-size: 12px !important;
}

// App-Like feeling
@media (max-width: 768px) {
    html,
    body,
    #root,
    .app {
        position: fixed;
        top: 0;
        left: 0;
        .nav-header {
            position: fixed !important;
            z-index: 10;
            border-radius: 0 !important;
        }
        .main_content {
            display: inline-block;
            padding-top: 70px;
            overflow: auto;
            width: 100%;
        }
    }
}

// Font overwriting
.ui.button,
.ui.modal > .header,
.ui.header,
h1,
h2,
h3,
h4,
h5,
input::placeholder,
.ui.message .header,
button,
input,
optgroup,
select,
textarea {
    font-family: 'PT Sans', sans-serif !important;
}

.ui.modal > .header,
.content_container .text.extra *,
.SearchField-Results center span,
.ui.comments .comment .author,
.ui.feed > .event > .content a,
.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
    color: #0e3560 !important;
}

// Button overwriting
.ui.button {
    box-shadow: 0 0 0px 0px rgba(224, 225, 226, 0.6) !important;
    &:focus {
        box-shadow: 0 0 0px 3px rgba(224, 225, 226, 0.6) !important;
    }
}

.ui.button.primary {
    box-shadow: 0 0 0px 0px rgba(33, 133, 208, 0.4) !important;
    &:focus {
        box-shadow: 0 0 0px 3px rgba(33, 133, 208, 0.4) !important;
    }
}

.ui.button.negative,
.ui.button.red {
    box-shadow: 0 0 0px 0px rgb(245 176 176 / 60%) !important;
    &:focus {
        box-shadow: 0 0 0px 3px rgb(245 176 176 / 60%) !important;
    }
}

.ui.button.positive {
    box-shadow: 0 0 0px 0px rgba(16, 164, 5, 0.4) !important;
    &:focus {
        box-shadow: 0 0 0px 3px rgba(16, 164, 50, 0.4) !important;
    }
}

.ui.button.black {
    box-shadow: 0 0 0px 0px rgba(47, 48, 50, 0.3) !important;
    &:focus {
        box-shadow: 0 0 0px 3px rgba(47, 48, 50, 0.3) !important;
    }
}

.ui.card,
.ui.cards > .card {
    border-radius: 8px !important;
}
