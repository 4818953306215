body {
    overflow: hidden;
}

.loginContainer {
    margin: 0;
    width: 500px;
    height: 100%;
    background: white;
    float: left;
    text-align: left;
    padding: 50px 35px;
    .ui.message {
        text-align: center;
    }
    img.logo {
        height: 40px;
    }
    h3 {
        margin-bottom: 23px;
        font-size: 21px;
        font-weight: bold;
        color: #2c86d0;
    }
    .formContainer {
        border-top: 1px solid #dededf;
        padding-top: 25px;
        margin-top: 25px;
        .ui.button {
            margin: 5px;
        }
        .or-text {
            display: block;
            font-size: 16px;
            margin: 15px 0;
        }
        .ui.button:first-of-type {
            margin-left: 0 !important;
        }
    }
}

.loginBackground {
    width: calc(100% - 500px);
    float: left;
    height: 100%;
    background-image: url('../../../static/login-background.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 49px 0px 30px -40px #0000003b;
}

.login-card {
    padding: 15px !important;
    width: 100% !important;
    margin-top: 50px !important;
    background: #fdfdfd !important;
}

@media (max-width: 650px) {
    .loginContainer {
        width: 100% !important;
    }
    .loginBackground {
        width: 0;
    }
}
