@import '../../../scss/variables';

.KnowledgeBaseSidebar {
    position: absolute;
    width: 300px;
    height: 100%;
    background-color: white;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    top: 0;
    left: 0;
    z-index: 0;
    padding: 65px 10px 10px;
    overflow: auto;
    z-index: 1;
    transition: all 0.2s;

    hr {
        height: 1px;
        background-color: #d4d4d5;
        border: none;
        margin: 0 0 4px 0;
    }

    &-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 11px 10px;
        background: white;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        color: #0e3560;
        margin-bottom: 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        transition: all 0.2s !important;
        &:hover {
            background-color: #eeeeee;
            transform: scale(0.98);
        }
        svg {
            margin-left: 5px;
            margin-right: 15px;
            opacity: 0.7;
        }
        &:active {
            transform: scale(0.95);
            outline: none !important;
        }
        &--active {
            background-color: #f7f7f7;
            color: $primary-color;
        }
        &:last-of-type {
            margin-bottom: 0 !important;
        }
    }

    &-mobile-button {
        display: none;
        height: 40px;
        width: 40px;
        border-radius: 100px;
        background: #f7f7f7;
        text-align: center;
        cursor: pointer;
        transition: all 0.2s;
        margin-bottom: 15px;
        position: relative;

        svg {
            margin: 8px;
            transform: none;
            transition: all 0.2s;
        }

        &:hover {
            color: #2185d0;
            background-color: #eeeeee;
            transform: scale(0.98);
        }
        &:active {
            transform: scale(0.93);
            outline: none !important;
        }
    }

    &--mobile-open {
        width: 90% !important;
        max-width: 400px !important;

        .KnowledgeBaseSidebar-mobile-button {
            svg {
                transform: rotate(180deg) !important;
            }
        }
    }

    @media (max-width: 768px) {
        width: 50px;
        padding: 65px 5px 5px;

        &:not(&--mobile-open) {
            *:not(.KnowledgeBaseSidebar-mobile-button):not(.KnowledgeBaseSidebar-mobile-button *) {
                opacity: 0;
                pointer-events: none;
                touch-action: none;
            }
        }

        &-mobile-button {
            display: inline-block;
        }
    }
}
