@import '../../scss/variables';

body {
    overflow: hidden;
}

.settings_sidebar {
    width: 290px;
    float: left;
    border-radius: 10px !important;
    box-shadow: 0 1px 3px 0 #d4d4d5;
    background: white !important;
    padding: 15px !important;

    &-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 11px 10px;
        background: white;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        color: #0e3560;
        margin-bottom: 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        transition: all 0.2s !important;
        &:hover {
            background-color: #eeeeee;
            transform: scale(0.98);
        }
        svg {
            margin-left: 5px;
            margin-right: 15px;
            opacity: 0.7;
        }
        &:active {
            transform: scale(0.95);
            outline: none !important;
        }
        &--active {
            background-color: #f7f7f7;
            color: $primary-color;
        }
        &:last-of-type {
            margin-bottom: 0 !important;
        }
    }

    @media (max-width: 1000px) {
        width: 100%;
    }
}
.settings_content {
    width: calc(100% - 290px - 20px);
    float: left;
    margin-left: 20px;

    border-radius: 10px !important;
    box-shadow: 0 1px 3px 0 #d4d4d5;
    background: white !important;
    padding: 15px !important;

    @media (max-width: 1000px) {
        width: 100%;
        margin: 20px 0 0 0;
    }

    &_loader {
        height: 130px !important;
        position: relative !important;
        padding: 0 !important;
    }
}
